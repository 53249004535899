import { useEffect } from "react";

import { useParams } from "react-router-dom";
import Card from "../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { singleCategoryFetching } from "../features/category/singleCategorySlice";
import { Triangle } from "react-loader-spinner";

const CategoryPage = () => {
  const dispatch = useDispatch();

  const { items: data, loading } = useSelector(
    (state) => state.categoryProduct
  );

  const { id } = useParams();

  useEffect(() => {
    dispatch(singleCategoryFetching(id));
  }, [dispatch, id]);
  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center min-h-[calc(100vh-25px)]">
          <Triangle
            visible={true}
            height="120"
            width="120"
            color="#000"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div>
          <div className="px-3 container mx-auto min-h-screen">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-6 mt-10 gap-2 md:gap-5">
              {data.length > 0 &&
                data?.map((product) => (
                  <div key={product.id} className="overflow-hidden">
                    <Card product={product} />
                  </div>
                ))}
            </div>
          </div>

          {data.length === 0 && (
            <>
              <div>
                <span>There is no product in this Category</span>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CategoryPage;

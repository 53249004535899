import React, { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineMinus } from "react-icons/ai";

import { BsPlus } from "react-icons/bs";
import RelatedProduct from "../components/RelatedProduct";
import {
  addToSingleCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "../components/SectionTitle";
import ProductImageCarasol from "../components/ProductImageCarasol";

import { toast } from "react-toastify";
import { thumbImageFetching } from "../features/products/thambImageSlice";
import { singleProductFetching } from "../features/products/signleProductSlice";
import { Parser } from "html-to-react";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { items: singleProduct } = useSelector((state) => state.singleProduct);

  const { items: thumbImage } = useSelector((state) => state.thumbImage);

  const [cartQuantity, setCartQuantity] = useState(1);

  // const [singleProduct, setSingleProduct] = useState({});

  // const [thumbImage, setThumbImage] = useState([]);
  const [size, setSize] = useState([]);
  const [color, setColor] = useState([]);

  const [colorAtr, setColorAtr] = useState(null);
  const [sizeAtr, setSizeAtr] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(singleProductFetching(id));
    dispatch(thumbImageFetching(id));
  }, [dispatch, id]);

  const htmlParser = new Parser();

  useEffect(() => {
    const colors = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-color/${singleProduct?.product?.color}`
      );

      return setColor(res?.data);
    };
    colors();
    const sizes = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-size/${singleProduct?.product?.size}`
      );

      return setSize(res?.data);
    };
    sizes();
  }, [singleProduct?.product?.color, singleProduct?.product?.size]);

  const handleDecrease = useCallback(() => {
    setCartQuantity(cartQuantity === 1 ? 1 : (prev) => prev - 1);
    cartQuantity > 1 &&
      toast.warn("Quantity Decreased", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }, [cartQuantity]);

  const handleIncrease = useCallback(() => {
    setCartQuantity((prev) => prev + 1);
    toast.warn("Quantity Increased", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }, []);

  // const handleColorChange = (event) => {
  //   setColorAtr(event.target.value);
  // };
  // const handleSizeChange = (event) => {
  //   setSizeAtr(event.target.value);
  // };

  const handleBuyNow = () => {
    if (
      singleProduct?.product?.min_qty <= cartQuantity &&
      cartQuantity <= singleProduct?.product?.current_stock
    ) {
      dispatch(removeAllFromCart());
      dispatch(
        addToSingleCart({
          ...singleProduct.product,
          sizeAtr,
          colorAtr,
          cartQuantity,
        })
      );
      navigate("/checkout");
    } else {
      if (singleProduct?.product?.min_qty > cartQuantity) {
        toast.warn(
          `please fullfil the min qty ${singleProduct?.product?.min_qty}`,
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        toast.warn(`Not Enough Product in Our Stock`, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const handleAddToCart = () => {
    dispatch(
      addToSingleCart({
        ...singleProduct.product,
        colorAtr,
        sizeAtr,
        cartQuantity,
      })
    );
  };
  // const handleBuyNow = () => {
  //   dispatch(removeAllFromCart());
  //   dispatch(addToSingleCart({ ...singleProduct.product, cartQuantity }));
  //   navigate("/checkout");
  // };
  return (
    <div className="container mx-auto md:my-10 min-h-screen">
      <div className="flex flex-col lg:flex-row md:px-10 gap-[2rem] lg:gap-[3rem] p-3 items-center lg:items-start">
        {/* Left site */}
        <ProductImageCarasol
          // key={singleProduct.id}
          thumbImage={thumbImage}
          singleProduct={`${process.env.REACT_APP_URL}/uploads/product/${singleProduct?.product?.photos}`}
        />

        {/* Right site */}
        {singleProduct?.status && (
          <div className="right flex lg:w-[40rem] w-full flex-col justify-start gap-3">
            <div className="md:text-4xl sm:text-3xl text-[#EB1E63] font-semibold">
              {singleProduct?.product?.name}
            </div>
            <div className="details flex flex-col gap-8 text-md mt-[12px]">
              <div className="flex gap-5">
                {singleProduct?.product?.unit_price ===
                  singleProduct?.product?.after_discount &&
                singleProduct?.product?.after_discount > 0 ? (
                  <div className="font-medium text-2xl">
                    ৳ {singleProduct?.product?.unit_price}
                  </div>
                ) : (
                  <div className="flex gap-5">
                    <span className="font-semibold line-through text-[#FA5303] decoration-200">
                      ৳ {singleProduct?.product?.unit_price}
                    </span>
                    <span className="font-medium text-2xl">
                      ৳ {+singleProduct?.product?.after_discount}
                    </span>
                  </div>
                )}

                {/* <div className="font-medium text-2xl">
                  {singleProduct?.product?.after_discount > 0 ? (
                    <>৳ {+singleProduct?.product?.after_discount}</>
                  ) : (
                    <>৳ {+singleProduct?.product?.unit_price}</>
                  )}
                </div> */}
              </div>
              <div className="flex gap-3">
                <span className="inline-block text-slate-600/50 text-[0.9rem] font-medium  uppercase items-center">
                  Quantity :
                </span>
                <div className="flex items-center gap-4 ">
                  <button
                    className=" bg-slate-500/30 rounded-full flex justify-center items-center font-semibold text-xs h-5 w-5 hover:bg-[#218698] hover:text-white duration-500"
                    onClick={() => handleDecrease(singleProduct?.product)}
                  >
                    <AiOutlineMinus />
                  </button>
                  <span>{cartQuantity}</span>
                  <button
                    className=" bg-slate-500/30 h-5 w-5  rounded-full flex justify-center items-center hover:bg-[#218698] hover:text-white duration-500"
                    onClick={() => handleIncrease(singleProduct?.product)}
                  >
                    <BsPlus />
                  </button>
                </div>
              </div>
              <div className="total-price flex gap-3 items-center ">
                <span className="inline-block text-slate-600/50 text-[0.9rem] font-medium uppercase items-center">
                  Total Price :
                </span>
                <span className="inline-block text-2xl font-semibold text-[#218698] ">
                  {singleProduct?.product?.after_discount > 0 ? (
                    <>{singleProduct?.product?.after_discount * cartQuantity}</>
                  ) : (
                    <>{singleProduct?.product?.unit_price * cartQuantity}</>
                  )}
                </span>
              </div>
            </div>

            {singleProduct?.product?.current_stock > 0 ? (
              <div className="flex mt-8 items-center font-semibold gap-5">
                <button
                  onClick={handleAddToCart}
                  className="py-3 px-5 rounded-full bg-[#EB1E63] text-white text-slate-50  hover:bg-[#DA303D] duration-300    items-center justify-center gap-2"
                >
                  Add to Cart
                </button>

                <button
                  onClick={handleBuyNow}
                  className="py-3 px-5 rounded-full  border bg-[#F9921F] text-white   hover:text-slate-50  duration-300 items-center justify-center gap-2"
                >
                  Buy Now
                </button>
              </div>
            ) : (
              <p>out of stock</p>
            )}
          </div>
        )}
      </div>
      <div className="px-3 mb-20 md:mb-15  md:px-0">
        <SectionTitle title={"Description"} />
        <div className="mb-10">
          {htmlParser.parse(singleProduct?.product?.description)}
        </div>

        <RelatedProduct singleProduct={singleProduct} />
      </div>
    </div>
  );
};

export default ProductDetails;
